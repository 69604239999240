/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

// import React from "react"

// import "../css/app.css"
// import "../css/responsive.css"

// const Layout = ({ children }) => {
//   return <>{children}</>
// }

// export default Layout
/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import "../css/app.css";
import "../css/responsive.css";

const Layout = ({ children }) => {
  // useEffect(() => {
  //   const dfMessenger = document.querySelector("df-messenger");
  //   const dfMessengerBubble = document.querySelector(
  //     "df-messenger-chat-bubble"
  //   );

  //   setTimeout(() => {
  //     // dfMessengerBubble.openChat();
  //     if (dfMessenger) {
  //       // dfMessenger.renderCustomText("Hello and welcome");
  //       dfMessenger.startNewSession();
  //     }
  //     dfMessenger.sendQuery("hi");
  //   }, 500);
  // }, []);

  // const buttonChatLoader = () => {
  //   const dfMessenger = document.querySelector("df-messenger");
  //   if (dfMessenger) {
  //     dfMessenger.sendQuery("hi");
  //   }
  // };

  return (
    <>
      <Helmet>
        {/* <script
          src="https://www.gstatic.com/dialogflow-console/fast/df-messenger/prod/v1/df-messenger.js"
          type="text/javascript"
          data-document-language="true"
        ></script> */}
        <script
          src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
          type="text/javascript"
          data-document-language="true"
          charset="UTF-8"
          data-domain-script="667ddcfb-5ec9-4dfb-8f85-1f828cf791c8-test"
        ></script>
      </Helmet>
      {children}
      {/* <button id="ot-sdk-btn" className="ot-sdk-show-settings">
        Cookie Settings
      </button> */}
    </>
  );
};

export default Layout;
